import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import "./App.css";
import { cacheBuster } from "./config";
import PrivacyPolicy from "./PrivacyPolicy";
import NewsChannels from "./NewsChannels";
import ScrollToTop from "./ScrollToTop";
import NotFound from "./NotFound";
import { DropdownProvider } from "./DropdownContext";
import Modal from 'react-modal';

Modal.setAppElement("#root");
function App() {
  const cssUrl = `./styles.css?v=${cacheBuster}`;

  const linkRef = useRef<HTMLLinkElement>(null);

  useEffect(() => {
    const linkElement = linkRef.current;

    if (linkElement) {
      linkElement.onload = () => {
        linkElement.media = "all";
      };
    }
  }, []);

  return (
    <DropdownProvider>
      <div className="App">
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<NewsChannels />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <link rel="stylesheet" href={cssUrl} media="print" ref={linkRef} />

          <noscript>
            <link rel="stylesheet" href={cssUrl} />
          </noscript>
        </Router>
      </div>
    </DropdownProvider>
  );
}

export default App;
