import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <a className="email" href="mailto:contact@hindi-news.live">
            contact@hindi-news.live
          </a>
          <span className="email">|</span>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span>|</span>
          <p>© 2024 www.hindi-news.live</p>
          <span>|</span>
          <p>All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
