import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const location = useLocation();
  const currentURL = `https://hindi-news.live/${location.pathname}`;

  return (
    <>
      <div className="Apps">
        <Helmet>
          <link rel="canonical" href={currentURL} />
          <title>
            Privacy Policy | Protecting Your Data on www.hindi-news.live
          </title>
          <meta
            name="description"
            content="Learn how www.hindi-news.live collects and protects your information. Understand our use of cookies, data security measures, and privacy practices. Stay informed about our policies."
          />
          <meta
            name="keywords"
            content="Privacy Policy, data protection, cookies, personal information, non-personal information, third-party content, news aggregator, external links, children's privacy, www.hindi-news.live"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            At www.hindi-news.live, we prioritize your privacy and are committed
            to protecting your personal information. This Privacy Policy
            outlines how we collect, use, and safeguard the data you provide
            when accessing our website. By continuing to use our services, you
            agree to the terms described here.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information Collection</h2>
              <p>
                We collect two types of information from users to improve your
                experience:
              </p>
              <ol className="dash">
                <li>
                  <h3>Personal Information</h3>: We do not gather personal
                  details like names, emails, or phone numbers unless you
                  provide them voluntarily through contact forms, newsletter
                  subscriptions, or account registration.
                </li>
                <li>
                  <h3>Non-Personal Information</h3>: We automatically collect
                  non-identifiable information, such as IP addresses, browser
                  types, visited pages, and the time spent on our site. This
                  data helps us optimize site performance and understand user
                  behavior.
                </li>
              </ol>
            </li>
            <li>
              <h3>Cookies and Tracking Tools</h3>
              <p>
                We use cookies and similar technologies to personalize your
                experience and analyze site traffic. Cookies enable us to
                enhance your interaction with the website by customizing content
                and improving features. You can manage or disable cookies
                through your browser settings, though doing so may limit certain
                functionalities of the website.
              </p>
            </li>
            <li>
              <h2>How We Use Your Data</h2>
              <p>The data we collect is used to:</p>
              <ul className="dash-list">
                <li>Optimize site performance and user experience.</li>
                <li> Personalize your interactions with the content.</li>
                <li>Track usage patterns and preferences.</li>
                <li>
                  Ensure compliance with legal obligations and protect our
                  rights.
                </li>
              </ul>
            </li>
            <li>
              <h2>Intellectual Property & Content Ownership</h2>
              <p>
                All articles, videos, and images on www.hindi-news.live are
                owned by their respective creators. As a news aggregator, we
                curate content from external sources and do not claim ownership
                of third-party content. If you believe that any material on our
                site infringes copyright laws, please contact us for prompt
                resolution.
              </p>
            </li>
            <li>
              <h2>External Links</h2>
              <p>
                Our platform may contain links to third-party websites. We are
                not responsible for the content or privacy practices of these
                external sites. We encourage you to review their privacy
                policies before sharing any personal information.
              </p>
            </li>
            <li>
              <h2>Data Protection</h2>
              <p>
                We take necessary measures to protect the information you share
                with us against unauthorized access, use, or disclosure.
                However, no online transmission can be guaranteed to be 100%
                secure, and we cannot ensure absolute security.
              </p>
            </li>
            <li>
              <h3>Children's Privacy</h3>
              <p>
                Our website is not designed for individuals under the age of 13.
                We do not knowingly collect any personal data from children. If
                a parent or guardian believes their child has submitted personal
                information, they should contact us, and we will take steps to
                delete the data promptly.
              </p>
            </li>
            <li>
              <h2> Policy Updates</h2>
              <p>
                We may modify this Privacy Policy from time to time to reflect
                changes in our practices or legal obligations. Any updates will
                be posted on this page and will take effect immediately after
                publication.
              </p>
            </li>
            <li>
              <h2>Contact Information</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or the practices of www.hindi-news.live, please reach out to us
                at: Email: contact@hindi-news.live
              </p>
            </li>
          </ol>
          <p>
            We are a news aggregator, and all content rights remain with the
            original creators. We do not claim ownership of third-party
            materials.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
